import styled from 'styled-components';

const Container = styled.div`
  ${({ customCss }) => customCss};
  .hoe--heading,
  .hoe--copy {
    p {
      margin: 0;
    }
  }
  .hoe--ctaLink {
    display: flex;
    align-items: baseline;
    width: fit-content;
    p {
      color: var(--Titan-Blue-3, #0265dc);

      font-family: 'Sofia Pro';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 21.6px */
      letter-spacing: -0.36px;
      margin: 0;
    }
  }
`;

export { Container };
