import React from 'react';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import ContentfulImage from 'components/WebsiteImage';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import Text from 'components/Core/Text';
import { ArrowRightIcon } from 'components/Brand/Icons/ArrowRightIcon';
import { Container } from './styles';

const Hoe = ({ entries = [], options, componentMap }) => {
  const heading = getEntry('heading', entries, componentMap);
  const description = getEntry('description', entries, componentMap);
  const cardsIncluded = new Set(
    entries
      .map((entry) => entry.templateId)
      .filter((id) => id && /card[123]/.test(id))
      .map((id) => id.match(/card[123]/)[0]),
  );

  const cards = Array.from(cardsIncluded)?.map((card) => ({
    icon: getEntry(`${card}Icon`, entries, componentMap),
    title: getEntry(`${card}Title`, entries, componentMap),
    copy: getEntry(`${card}Copy`, entries, componentMap),
    cta: getEntry(`${card}Link`, entries, componentMap),
  }));

  const styles = entries.filter((entry) => entry?.templateId === 'section')?.[0]?.styles;
  const theme = options?.Theme;
  const customCss = styles?.customStyling?.customStyling;

  const renderCard = (card) => (
    <div className="flex flex-col py-[30px]">
      <div className="flex items-center gap-[10px] pb-[23px]">
        {card.icon && <ContentfulImage {...card.icon} className="!max-w-[35px]" />}
        {card.title && <ContentfulRichText className="hoe--heading" richText={card.title} />}
      </div>
      <div className="pb-[30px]">
        {card.copy && <ContentfulRichText className="hoe--copy" richText={card.copy} />}
      </div>
      <a href={card.cta?.url} className="hoe--ctaLink">
        {card.cta?.buttonLabel && <Text type="p">{card.cta?.buttonLabel}</Text>}
        <ArrowRightIcon width="24" />
      </a>
    </div>
  );

  return (
    <Container
      className="flex flex-col bg-black p-[20px] md:px-[117px] md:py-[58px] gap-[20px] md:gap-[30px]"
      theme={theme}
      customCss={customCss}
    >
      <div className="w-full bg-white p-[40px]">
        <div className="flex flex-col gap-[30px]">
          {heading && <ContentfulRichText richText={heading} />}
          {description && <ContentfulRichText richText={description} />}
        </div>
        <div className="flex flex-col md:flex-row md:gap-[40px] px-[20px]">
          {cards?.map((card) => card && renderCard(card))}
        </div>
      </div>
    </Container>
  );
};

export default Hoe;
